<template>
  <el-dialog :visible.sync="visible" fullscreen :show-close="false">
    <div class="full-wrapper">
      <span>推广活动</span>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'promotion-dialog.vue',
  data () {
    return {
      visible: false
    }
  },
  methods: {
    init () {
      this.visible = true
    }
  }
}
</script>

<style scoped>
  .full-wrapper {
    background-color: #409eff;
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
  }
</style>
